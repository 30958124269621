import { Grid, Paper, Typography } from '@material-ui/core';
import { capitalize, flow, map, sortBy, uniq } from 'lodash/fp';

import Bio from '../components/Bio';
import Layout from '../components/Layout';
import React from 'react';
import SEO from '../components/seo';
import SocialProfiles from '../components/SocialProfiles';
import { blue } from '@material-ui/core/colors';
import { graphql } from 'gatsby';

const DATA = [
  'Art',
  'Authenticity',
  'Balance',
  'Branding',
  'Breathing',
  'Capitalism',
  'Career',
  'Clarity',
  'Coffee',
  'Communication',
  'Connection',
  'Creativity',
  'Culture',
  'Dancing',
  'Emotions',
  'Empathy',
  'Entrepreneurship',
  'Exercising',
  'Focus',
  'Food',
  'Gait',
  'Habits',
  'Health',
  'Journaling',
  'Learning',
  'Marketing',
  'Meditation',
  'Parenting',
  'Perfectionism',
  'Posture',
  'Programming',
  'Qigong',
  'Rhythm',
  'Sleep',
  'Teaching',
  'Tinnitus',
  'Vision',
  'Writing',
];

const HeaderType = ({ children }) => (
  <Typography
    variant={'overline'}
    color={'textSecondary'}
    style={{ textTransform: 'uppercase' }}
  >
    {children}
  </Typography>
);

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title='My Interests'
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Typography variant={'h5'} gutterBottom>
          My interests
        </Typography>

        <Typography variant={'body2'} color={'textSecondary'} gutterBottom>
          I am struggling to focus on a single thing. Here is a list of stuff I
          am putting my time and effort to digg into. I would love to share,
          chat or write on any of this topics.
        </Typography>

        <Typography variant={'body2'} color={'textSecondary'} gutterBottom>
          Also, there is a{' '}
          <a
            style={{ color: blue[500] }}
            href={'https://www.facebook.com/groups/rg.journey.ama/'}
          >
            Facebook group
          </a>{' '}
          I've created where you can post any question for me to share my
          perspective.
        </Typography>
        <br />
        <Grid container spacing={1}>
          {flow(
            map(capitalize),
            sortBy((a) => a),
            uniq,
            map((title) => (
              <Grid item xs={6} md={6} lg={4} key={title}>
                <Paper style={{ padding: '12px 20px' }} elevation={0}>
                  <Typography>{title}</Typography>
                </Paper>
              </Grid>
            ))
          )(DATA)}
        </Grid>
        <br />
        <br />
        <Bio />
        <br />
        <br />
        <Typography color={'textSecondary'} variant={'overline'}>
          Connect
        </Typography>
        <SocialProfiles />
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
